import { get, set } from 'idb-keyval';
import { v4 as uuidv4 } from 'uuid';
import API from './api';

// eslint-disable-next-line import/no-extraneous-dependencies
const Emitter = require('tiny-emitter');

const emitter = new Emitter();
const indexed = process.env.VUE_APP_INDEXED_DB;
const indexedBackup = process.env.VUE_APP_INDEXED_DB_BACK;

export default {
  emitter,
  generateUUID() {
    return uuidv4();
  },
  // async insertIndexedDB({ name, data }) {
  //   if (!name || !data) return false;
  //   const arr = Array.isArray(data) ? data : [data];
  //   const db = await this.getIndexedDB(name);
  //   const newDB = Array.isArray(db) ? [...db, ...arr] : arr;
  //   await set(name, JSON.stringify(newDB));
  //   return true;
  // },
  async insertIndexedDB({ name, data, trivia = false }) {
    if (name && data) {
      if (!trivia) {
        await set(name, JSON.stringify(data));
      } else {
        const db = await this.getIndexedDB(indexed);

        if (db) {
          await set(name, JSON.stringify([
            ...db,
            ...data,
          ]));
        } else {
          await set(name, JSON.stringify(data));
        }
      }

      return true;
    }
    return false;
  },
  async getIp() {
    try {
      const response = await fetch('https://api.ipify.org/?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  async getIndexedDB(name = null) {
    if (name !== null) {
      const db = await get(name);
      return db ? JSON.parse(db) : [];
    }
    return [];
  },
  async searchEntry(uuid) {
    const entries = JSON.parse(await get(indexed));
    return entries.find((entry) => entry.uuid === uuid);
  },
  async updateEntries({ name, user, data }) {
    if (name && user) {
      const obj = JSON.parse(await get(name));
      const update = obj.map((item) => {
        const updateData = item;
        if (item.uuid === user && data) {
          Object.keys(data).forEach((prop) => {
            updateData[prop] = data[prop];
          });
        }
        return updateData;
      });
      set(name, JSON.stringify(update));
    }
  },
  isSyncing: false,
  async syncInsertEntries() {
    if (this.isSyncing) {
      return;
    }

    try {
      this.isSyncing = true;

      const db = await get(indexed);
      if (db) {
        let entries = JSON.parse(db || '[]');

        const reqIp = await this.getIp();

        entries = entries.map((item) => {
          const entry = { ...item };
          entry.reqIp = reqIp;

          return entry;
        });

        const entriesPending = entries.filter((entry) => entry.completionReason !== '');

        if (entriesPending.length > 0) {
          const { data } = await API.post('/sync', { data: entriesPending });
          if (data.success) {
            const entriesOld = JSON.parse(await get(indexedBackup) || '[]');
            const newEntries = entries
              .filter((entry) => !entriesPending
                .some((entrySync) => entry.uuid === entrySync.uuid));
            await Promise.all([
              set(indexedBackup, JSON.stringify([...entriesOld, ...entriesPending])),
              set(indexed, JSON.stringify(newEntries)),
            ]);
            console.log('Entries Synced and backed up!');
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isSyncing = false;
    }
  },
  preloadImages(fileArray) {
    const files = [];
    for (let i = 0; i < fileArray.length; i += 1) {
      const extension = fileArray[i].src.split('.').pop();
      if (extension === 'js') {
        files[i] = document.createElement('script');
        files[i].src = fileArray[i].src;
      } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        files[i] = new Image();
        files[i].src = fileArray[i].src;
      }
    }
    return files;
  },
  validateDate(date) {
    const separateDate = date.split('-');
    const year = parseInt(separateDate[0], 10);
    const month = parseInt(separateDate[1], 10);
    const day = parseInt(separateDate[2], 10);

    const dateObj = new Date(year, month - 1, day);
    const dateYear = dateObj.getFullYear();
    const dateMonth = dateObj.getMonth() + 1;
    const dateDay = dateObj.getDate();

    return dateYear === year && dateMonth === month && dateDay === day;
  },
  addAnimationTop() {
    const content = document.querySelector('.animation');
    content.classList.add('animation-hidden');
  },
  animationTrivia() {
    const content = document.querySelector('.modal_next');
    content.classList.remove('animate__animated', 'animate__delay-0.5s', 'animate__fadeInUp', 'animate-retry');
    content.classList.add('animation-trivia');
  },
};
